import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/statistics",
        name: "statistics",
        component: () => import("@/views/apps/reports/statistics.vue"),
      },
      {
        path: "/reportExports",
        name: "ReportExports",
        component: () => import("@/views/apps/reportExports/ReportExports.vue"),
      },
      {
        path: "/timeline",
        name: "timeline",
        component: () => import("@/views/MessageList.vue"),
      },
      {
        path: "/arriving",
        name: "arriving",
        component: () => import("@/views/Arriving.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/students",
        name: "Estudantes",
        component: () => import("@/views/apps/colaborators/Students.vue"),
      },
      {
        path: "/teachers",
        name: "Professores",
        component: () => import("@/views/apps/colaborators/Teachers.vue"),
      },

      {
        path: "/responsibles",
        name: "Responsáveis",
        component: () => import("@/views/apps/colaborators/Responsibles.vue"),
      },
      {
        path: "/usersonline",
        name: "Usuários Online",
        component: () => import("@/views/apps/colaborators/UsersOnline.vue"),
      },
      {
        path: "/authorized",
        name: "Usuários Autorizados",
        component: () => import("@/views/apps/colaborators/Authorized.vue"),
      },
      {
        path: "/terms",
        name: "Termos",
        component: () => import("@/views/apps/terms/index.vue"),
      },
      {
        path: "/colaborators",
        name: "Colaboradores",
        component: () => import("@/views/apps/colaborators/Colaborators.vue"),
      },
      {
        path: "/roles",
        name: "Roles",
        component: () => import("@/views/apps/roles/Roles.vue"),
      },
      {
        path: "/messages",
        name: "Messages",
        component: () => import("@/views/apps/messages/index.vue"),
      },
      {
        path: "/intecorrence",
        name: "intecorrence",
        component: () => import("@/views/apps/intecorrence/index.vue"),
      },
      {
        path: "/intecorrenceDashboard",
        name: "intecorrenceDashboard",
        component: () =>
          import("@/views/apps/intecorrence/intecorrenceDashboard.vue"),
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("@/views/apps/notification/index.vue"),
      },
      {
        path: "/templates",
        name: "Templates",
        component: () => import("@/views/apps/messages/templates.vue"),
      },
      {
        path: "/messagesPending",
        name: "messagesPending",
        component: () => import("@/views/apps/messages/messagesPending.vue"),
      },
      {
        path: "/newMessage",
        name: "newMessage",
        component: () => import("@/views/apps/messages/newMessage.vue"),
      },
      {
        path: "/updateMessage/:id",
        name: "updateMessage",
        component: () => import("@/views/apps/messages/updateMessage.vue"),
      },
      {
        path: "/messagePicture",
        name: "messagePicture",
        component: () => import("@/views/apps/messages/messagePicture.vue"),
      },
      {
        path: "/searchMessage",
        name: "searchMessage",
        component: () => import("@/views/apps/messages/searchMessage.vue"),
      },
      {
        path: "/reportMessage",
        name: "reportMessage",
        component: () => import("@/views/apps/messages/reportMessage.vue"),
      },
      {
        path: "/messagesInteractions/:id",
        name: "MessagesInteractions",
        component: () =>
          import("@/views/apps/messages/messageInteractions.vue"),
      },
      {
        path: "/tickets",
        name: "tickets",
        component: () => import("@/views/apps/tickets/index.vue"),
      },
      {
        path: "/myTickets",
        name: "myTickets",
        component: () => import("@/views/apps/tickets/myTickets.vue"),
      },
      {
        path: "/ticketPending",
        name: "ticketPending",
        component: () => import("@/views/apps/tickets/pendingTickets.vue"),
      },
      {
        path: "/atendentTickets",
        name: "atendentTickets",
        component: () => import("@/views/apps/tickets/atendentTickets.vue"),
      },
      {
        path: "/integration",
        name: "integration",
        component: () => import("@/views/apps/integration/Integration.vue"),
      },
      {
        path: "/import",
        name: "import",
        component: () => import("@/views/apps/import/Import.vue"),
      },
      {
        path: "/survey",
        name: "survey",
        component: () => import("@/views/apps/survey/newSurvey.vue"),
      },
      {
        path: "/surveys",
        name: "surveys",
        component: () => import("@/views/apps/survey/surveys.vue"),
      },
      {
        path: "/showSurvey/:id",
        name: "showSurvey",
        component: () => import("@/views/apps/survey/showSurvey.vue"),
      },
      {
        path: "/mobile",
        name: "itemMenu",
        component: () => import("@/views/apps/mobile/Mobile.vue"),
      },
      {
        path: "/completedTickets",
        name: "completedTickets",
        component: () => import("@/views/apps/tickets/completedTickets.vue"),
      },
      {
        path: "/attendanceTickets",
        name: "attendanceTickets",
        component: () => import("@/views/apps/tickets/attendanceTickets.vue"),
      },
      {
        path: "/channelTickets",
        name: "channelTickets",
        component: () => import("@/views/apps/tickets/channelTickets.vue"),
      },
      {
        path: "/answer",
        name: "answer",
        component: () => import("@/views/apps/tickets/answer.vue"),
      },
      {
        path: "/tickets/:id",
        name: "ticketShow",
        component: () => import("@/views/apps/tickets/showTicket.vue"),
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import("@/views/apps/documents/index.vue"),
      },
      {
        path: "/classes",
        name: "classes",
        component: () => import("@/views/apps/classes/index.vue"),
      },
      {
        path: "/classes/:id",
        name: "classShow",
        component: () => import("@/views/apps/classes/showClass.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/crafted/authentication/Login.vue"),
      },
      {
        path: "/research-satisfaction/:senderId/:ticketId",
        name: "researchSatisfaction",
        component: () =>
          import("@/views/crafted/authentication/researchSatisfaction.vue"),
      },
      {
        path: "/resetPasswordSend",
        name: "ResetPasswordSend",
        component: () =>
          import("@/views/crafted/authentication/ResetPasswordSend.vue"),
      },
      {
        path: "/resetPassword/:token",
        name: "ResetPassword",
        component: () =>
          import("@/views/crafted/authentication/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/faceIdRegistration/:unityId",
    name: "FaceIdRegistration",
    component: () => import("@/views/crafted/FaceIdRegistration.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/extenalForm/:id/:formId",
    name: "404",
    component: () => import("@/views/apps/messages/externalForm.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, {
    data: { api_token: JwtService.getToken() },
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
